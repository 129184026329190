/* .ticket-app-container {
  height: 100vh;
  width: 100%;
  position: relative;
}
.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  padding: 60px 30px;
}

.gradient-overlay {
  position: absolute;
  width: 200%;
  height: 200%;
  background: conic-gradient(
    from 0deg,
    rgba(255, 0, 150, 0.2),
    rgba(0, 255, 255, 0.2),
    rgba(255, 255, 0, 0.2),
    rgba(255, 0, 150, 0.2)
  );
  filter: blur(150px);
  opacity: 0.8;
  pointer-events: none;
  transform-origin: center;
}

.particle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  filter: blur(5px);
}

.ticket {
  background: white;
  max-width: 300px;
  margin-bottom: 10px;
  padding: 10px;
}

.ticket-form {
  min-width: 300px;
  max-width: 400px;
  justify-content: space-between;
  margin-top: 50px;
  padding: 20px;
}

.tickets {
  padding: 30px;
}
.tictket-textare {
  padding: 10px;
  max-width: 400px;
  min-width: 300px;
  width: 100%;
} */
.container-tic {
  height: 100vh;
  overflow: auto;
  padding: 60px 30px;
  width: 100%;
}
.ticket > h3 {
  font-weight: bold;
  text-transform: capitalize;
}
.ticket-app-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.ticket-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.ticket-form h2 {
  margin-bottom: 15px;
  color: #333;
}

.ticket-form input,
.ticket-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
}

.ticket-form .btn-submit {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.ticket-form .btn-submit:hover {
  background: #0056b3;
}

.tickets {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ticket {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.ticket h3 {
  margin: 0;
  color: #333;
}

.ticket p {
  color: #666;
}

.ticket .status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

.status.pending {
  background: #ffc107;
  color: #333;
}

.status.resolved {
  background: #28a745;
  color: white;
}

.status.received {
  background: #17a2b8;
  color: white;
}

.ticket small {
  display: block;
  margin-top: 8px;
  color: #777;
}

.actions {
  margin-top: 10px;
}

.actions button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.actions button:hover {
  opacity: 0.8;
}

.actions button:nth-child(1) {
  background: #17a2b8;
  color: white;
}

.actions button:nth-child(2) {
  background: #28a745;
  color: white;
}

.actions button:nth-child(3) {
  background: #dc3545;
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.pagination button:hover {
  background: #0056b3;
}

.pagination .active {
  background: #28a745;
}
