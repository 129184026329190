.welcome-text {
  font-weight: bold;
  margin-bottom: 1rem;
}
.main-content {
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.8;
}
.bg-transparent-card {
  background: blue;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 0 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 900px) {
  .welcome-text {
    font-size: 2rem;
  }
  .main-content {
    font-size: 0.95rem;
    padding: 0 30px;
  }
  .bg-transparent-card {
    width: 75px;
    height: 100px;
  }
}

@media only screen and (min-width: 901px) {
  .welcome-text {
    font-size: 3rem;
  }
  .main-content {
    font-size: 1.3rem;
  }
  .bg-transparent-card {
    width: 150px;
    height: 200px;
  }
}
