@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kolker+Brush&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  cursor: url(./asset/cursor.png), auto;
  /* scrollbar-width: none; Makes the scrollbar thinner */
}

:root {
  --red: red;
  --black: black;
  --yellow: yellow;
  --white: white;
}
body {
  background: url(./asset/bg/15717684_SL_120319_25700_14.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
a {
  text-decoration: none;
}

/*main*/
main {
  color: white;
}
img {
  display: block;
  /* animation: animate 1s linear infinite; */
  filter: drop-shadow(2px 4px 6px red);
}

.aside {
  background: black;
  color: white;
  width: 300px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  padding: 60px 30px;
  height: 100vh;
  min-width: 300px;
}
.private-area {
  display: flex;
}
aside > a {
  text-transform: capitalize;
  padding: 5px 10px;
  color: white;
}

.bullet-sec {
  padding: 20px;
}
.bullet-sec > li {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

@keyframes animate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

/*footer*/
footer {
  font-family: "Kolker Brush", cursive;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  color: var(--red);
  font-size: 1.8rem;
  background: black;
  height: 10vh;
}

html {
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

/* Apply custom scrollbar styles */
::-webkit-scrollbar {
  width: 5px; /* Set width for vertical scrollbar */
  height: 10px; /* Set height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #0ccf85; /* Background color of the track */
  border-radius: 6px; /* Rounded corners */
}
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners for handle */
  border: 2px solid #f0f0f0; /* Add space around the handle */
}
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

@media only screen and (max-width: 900px) {
  main {
    padding: 60px 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  /* .main-content {
    width: 100%;
    margin: 0;
  } */
  .private-area {
    flex-direction: column-reverse;
  }
  .aside {
    height: auto;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 10px;
  }
  aside > a {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 901px) {
  main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
  }
}

.whatapp {
  background: #25d366;
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  bottom: 3%;
  right: 4%;
  position: fixed;
  cursor: pointer;
  z-index: 3;
  animation: boxshadow 1s infinite alternate;
}
