@import url("https://fonts.googleapis.com/css2?family=Faster+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nosifer&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ruthie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ruthie&family=Tillana:wght@400;500;600;700;800&display=swap");
/*header*/
.header {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: black;
  backdrop-filter: blur(10px);
  color: white;
  box-shadow: 0px 0px 30px transparent;
  width: 100%;
  z-index: 3;
}
nav > a {
  list-style-type: none;
  padding: 0 5px;
  cursor: url(../../asset/pointer32.png), auto;
  color: white;
  text-transform: capitalize;
  margin-right: 15px;
  padding: 0px 10px;
}
a.active {
  color: red;
  box-shadow: 1px 1px 5px red;
  font-weight: bold;
}
nav > a:hover {
  color: var(--red);
}
/* h3, */
h3 > span {
  font-size: 2rem;
  font-family: "Kolker Brush", cursive;
  font-weight: bold;
}
h3 > span {
  color: var(--red);
}
nav {
  display: flex;
  align-items: center;
}
.logout,
.login,
.signup {
  background: var(--red);
  padding: 0 20px;
  border: none;
  cursor: url(../../asset/cursor32.png), auto;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 30px;
  font-family: "Kolker Brush", cursive;
  font-size: 2rem;
  /* margin-top: 10px; */
}

/* section.sidebar {
  background: red;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
} */
.sidebar {
  width: 250px;
  height: 100vh;
  background: rgb(173 173 173 / 10%); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Border for glass effect */
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 30px;
  /* align-items: center; */
  z-index: 3;
}

.sidebar > a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  background: none;
}

.sidebar > a:hover {
  color: red;
}

p.descrip {
  font-size: 0.7rem;
  position: absolute;
  width: 210px;
  top: 25px;
}

/* .login-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-container {
  background-color: #222;
  padding: 40px;
  width: 400px;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.login-box {
  color: white;
  transition: all 0.8s ease;
  transform: rotateY(0deg);
}

.rotate-box {
  transform: rotateY(360deg);
}

.login-content {
  display: flex;
  flex-direction: column;
}

input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

button[type="submit"] {
  background-color: red;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
} */

.login-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
}

.login-container {
  background-color: #222;
  padding: 40px;
  width: 400px;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.form-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-content {
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
}

.move-left {
  transform: translateX(0%);
  opacity: 0;
}

.move-right {
  transform: translateX(0%);
  opacity: 0;
}

.rotateY {
  backface-visibility: hidden;
}

input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

button[type="submit"] {
  background-color: red;
  color: white;
  padding: 0 25px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

svg.dashborad-icon {
  /* backdrop-filter: drop-shadow(2px 4px 6px yellow); */
  color: red;
  font-size: 1.3rem;
  margin-right: 10px;
  cursor: url(../../asset/cursor.png), auto;
}

@media only screen and (max-width: 900px) {
  .header {
    height: auto;
    position: fixed;
  }
  .heading-section {
    line-height: 0.5px;
  }
  .desk-nav {
    display: none;
  }
  svg.login-closer {
    font-size: 2rem;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .login-page {
    z-index: 6;
  }
  .mob-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    font-size: 1.5rem;
    margin-left: 10px;
  }
  .mob-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .mob-nav > a {
    margin-bottom: 10px;
  }
  .logout,
  .login {
    display: none;
  }
  p.descrip {
    margin-top: 15px;
    font-size: 0.6rem;
    position: static;
    width: auto;
  }
  .logout-mob {
    background: var(--red);
    border: none;
    cursor: url(../../asset/cursor32.png), auto;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 30px;
    /* font-family: "Tillana", system-ui;
    font-size: 0.8rem; */
    padding: 0 10px;
    font-family: "Kolker Brush", cursive;
    font-size: 2rem;
  }
  .sidebar-mob {
    position: fixed;
    bottom: -61px;
    top: 0;
    background: #000000a6;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    left: 0;
    padding: 10px;
    height: 150vh;
    width: 100%;
    z-index: 3;
  }
  .login-content > .login {
    display: block;
  }
}
@media only screen and (min-width: 901px) {
  .heading-section {
    position: relative;
    height: 40px;
    width: 200px;
  }
  .logout-mob,
  .sidebar-mob,
  .mob-btn {
    display: none;
  }
}

.google-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #4285f4; /* Google blue */
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 30px auto;
}

.google-btn:hover {
  background-color: #357ae8; /* Darker blue on hover */
  transform: translateY(-2px);
}

.google-btn:active {
  background-color: #3367d6; /* Even darker blue on click */
  transform: translateY(0);
}

.google-icon {
  font-size: 1.2rem;
}
